import React from "react";
import NotSupportedImg from "../../../assets/images/notSupported.svg";
const MobileWarning = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen gap-4">
      <img
        src={NotSupportedImg}
        alt="Device not support"
        className="w-[230px]"
      />
      <div className="w-11/12 h-fit flex flex-col items-center justify-center gap-2">
        <h1 className="text-base font-semibold">Device not supported</h1>
        <p className="text-[#7987A5] text-center font-normal leading-6">
          Mobile usage is not supported for this web app. We recommend accessing
          it on a desktop device.
        </p>
      </div>
    </div>
  );
};

export default MobileWarning;
