import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const getDeviceId = async () => {
  const fpPromise = FingerprintJS.load();

  try {
    const fp = await fpPromise;
    const result = await fp.get();
    if (await result.visitorId) {
      return result.visitorId;
    }
  } catch (error) {
    alert(error);
  }
  // Get the visitor identifier when you need it.
};

export const setInputFilter = (value: any) => {
  return /^\d*\.?\d*$/.test(value);
};

export const titleCase = (str: string) => {
  var splitStr = str?.toLowerCase()?.split(" ");
  for (var i = 0; i < splitStr?.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
  }
  // Directly return the joined string
  return splitStr?.join(" ");
};
export const avatarCase = (str: string) => {
  var splitStr = str?.toUpperCase()?.slice(0,2)
 return splitStr
};
export const getCourseMode = (mode: string) => {
  switch (mode) {
    case "asdaTalk":
      return "ASDA Talk";

    case "blendedCourse":
      return "Blended Course";

    case "onlineCourse":
      return "Online Course";

    default:
      return "N/A";
  }
};

export const truncate = (input: string, length?: number) =>
  input.length > 5 ? `${input.substring(0, length || 5)}...` : input;

//convert to IST
export function convertToIST(timeString: any) {
  const utcDate = new Date(timeString);
  const istDate = new Date(utcDate.getTime()); // Adding 5 hours 30 minutes

  const options = {
    // timeZone: "Asia/Kolkata",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const istTimeString = istDate.toLocaleString("en-IN", options);
  return istTimeString;
}

//prevent add e into number
export const blockInvalidChar = (e:any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

// Example usage
// const utcTime = '2023-08-23T15:15:18.860+00:00';
// const istTime = convertToIST(utcTime);

//Block site in small devices
export const blockApp = (): boolean => {
  let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile;
};
