import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { setupInterceptors } from "./utils/service";
import CustomRouter from "./utils/customRouter";
import history from "./utils/history";
import ErrorBoundary from "./pages/common/ErrorBoundary";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { api } from "./services/api";
import "./index.css";
import { blockApp } from "./utils/functions";
import MobileWarning from "./components/common/mobileWarning";

setupInterceptors(store);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
const isMobile = blockApp();
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <ApiProvider api={api}>
          <CustomRouter history={history}>
            {isMobile ? <MobileWarning /> : <App />}
          </CustomRouter>
        </ApiProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
