// ProtectedRoute.tsx

import React, { useEffect } from "react";
import { Route, Navigate, RouteProps, Outlet } from "react-router-dom";

interface ProtectedRouteProps extends RouteProps {}

const PrivateRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");
  
  return isAuthenticated ? <Outlet /> : <Navigate to="/auth/login" replace />; 
};

export default PrivateRoute;
