import io from 'socket.io-client';
import API from '../config/api';

class SocketService {
  initializeSocket = async () => {
    try {
      this.socket = io(process.env.REACT_APP_BASE_URL+API.SOCKET_SERVICE, {
        transports: ['websocket'],
      });
      // this.socket = io('ws://159.65.154.157:8889/chat-service-socket', {
      // this.socket = io('http://159.65.154.157:9000/chat-service-socket', {
      //   transports: ['websocket'],
      // });
      console.log(`initializing socket to ${process.env.REACT_APP_BASE_URL+API.SOCKET_SERVICE}`, this.socket);

      this.socket.on('connect', data => {
        console.log('=== socket connected ====');
      });

      this.socket.on('disconnect', data => {
        console.log('=== socket disconnected ====');
      });

      this.socket.on('error', data => {
        console.log('socekt error', data);
      });
    } catch (error) {
      console.log('socket is not inialized', error);
    }
  };

  emit(event, data = {}) {
    this.socket.emit(event, data);
  }

  on(event, cb) {
    this.socket.on(event, cb);
  }

  removeListener(listenerName) {
    this.socket.removeListener(listenerName);
  }

  disconnect() {
    this.socket.disconnect();
  }
}

const socketService = new SocketService();

export default socketService;
