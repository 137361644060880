import React from "react";
import Lottie from "react-lottie";

import LoadingLottie from '../../../assets/JSON/Lotties/pm_animation.json'

const Loader: React.FC = () => {


  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="w-full h-fit flex flex-col justify-center items-center">
      <span>
        <Lottie
          options={lottieOptions}
          width={"12rem"}
          height={"12rem"}
          isClickToPauseDisabled={true}
        />
      </span>
      <p className="font-mono">Loading...</p>
    </div>
  );
};

export default Loader;
