import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PUBLIC, PRIVATE, ERROR, AUTH } from "./routes";
import { useAppSelector } from "../store/hooks";

import Loader from "../components/common/loader";
import PrivateRoute from "./PrivateRoute";

// import Tasks from "../pages/admin/tasks";
// import Country from "../pages/admin/country";

const PublicLayout = lazy(() => import("../layouts/publicLayout"));
const AuthLayout = lazy(() => import("../layouts/authLayout"));
const AdminLayout = lazy(() => import("../layouts/adminLayout"));

const Landing = lazy(() => import("../pages/public/landing"));

const Login = lazy(() => import("../pages/auth/login"));

const Dashboard = lazy(() => import("../pages/admin/dashboard"));
const Tasks = lazy(() => import("../pages/admin/tasks"));
const Agents = lazy(() => import("../pages/admin/agents"));
const Users = lazy(() => import("../pages/admin/users"));
const SingleSubscriber = lazy(() => import("../pages/admin/singleSubscriber"));
const Process = lazy(() => import("../pages/admin/process"));
const ProcessType = lazy(() => import("../pages/admin/processType"));
const Country = lazy(() => import("../pages/admin/country"));
const Banner = lazy(() => import("../pages/admin/banner"));
const Chat = lazy(() => import("../pages/admin/chat"));
const Company = lazy(() => import("../pages/admin/company"));
const CompanyUsers = lazy(() => import("../pages/admin/company/users"));
const Settings = lazy(() => import("../pages/admin/settings"));
const ServiceRestart = lazy(() => import("../pages/admin/reset"));
const NewProcess = lazy(() => import("../pages/admin/newProcess"));
const SingleSubProcess = lazy(() => import("../pages/admin/newSingleProcess"));
const SingleSubscriberTask = lazy(
  () => import("../pages/admin/singleSubscriberStepList")
);

const Error = lazy(() => import("../pages/common/Error"));

const MainRoute: React.FC<any> = () => {
  // const { isAppInitialized } = useAppSelector((state: any) => state.user);
  const token = localStorage.getItem("token");

  // if (!isAppInitialized) {
  //   return <Loader />;
  // }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public Routes */}
        <Route path={PUBLIC.BASE_PATH} element={<PublicLayout />}>
          <Route
            path={PUBLIC.PAGES.LANDING}
            element={
              <Suspense fallback={<Loader />}>{/* <Landing /> */}</Suspense>
            }
          />

          {/* <Route index element={<Navigate to={PUBLIC.PAGES.LANDING} />} /> */}
          {token ? (
            <Route
              index
              element={
                <Navigate
                  to={`${PRIVATE.ADMIN.ADMIN_BASE_PATH}/${PRIVATE.ADMIN.PAGES.DASHBOARD}`}
                />
              }
            />
          ) : (
            <Route
              index
              element={
                <Navigate to={`${AUTH.BASE_PATH}/${AUTH.PAGES.LOGIN}`} />
              }
            />
          )}
        </Route>

        {/* Auth Routes */}
        <Route path={AUTH.BASE_PATH} element={<AuthLayout />}>
          <Route
            path={AUTH.PAGES.LOGIN}
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />

          <Route index element={<Navigate to={AUTH.PAGES.LOGIN} />} />
        </Route>

        {/* Super Admin Routes */}

        <Route  element={<PrivateRoute />}>
          <Route path={PRIVATE.ADMIN.ADMIN_BASE_PATH} element={<AdminLayout />}>
            <Route
              path={PRIVATE.ADMIN.PAGES.DASHBOARD}
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.PROCESS}
              element={
                <Suspense fallback={<Loader />}>
                  {/* <Tasks /> * Renames UI as Process */}
                  <NewProcess />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.AGENTS}
              element={
                <Suspense fallback={<Loader />}>
                  <Agents />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.USERS}
              element={
                <Suspense fallback={<Loader />}>
                  <Users />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.USERS_SINGLE}
              element={
                <Suspense fallback={<Loader />}>
                  <SingleSubscriber />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.TASKS}
              element={
                <Suspense fallback={<Loader />}>
                  <Process /> {/** Renames UI as Tasks */}
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.PROCESS_TYPE}
              element={
                <Suspense fallback={<Loader />}>
                  <ProcessType />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.COUNTRY}
              element={
                <Suspense fallback={<Loader />}>
                  <Country />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.BANNER}
              element={
                <Suspense fallback={<Loader />}>
                  <Banner />
                </Suspense>
              }
            />

            <Route
              path={PRIVATE.ADMIN.PAGES.COMPANY}
              element={
                <Suspense fallback={<Loader />}>
                  <Company />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.COMPANY_USERS}
              element={
                <Suspense fallback={<Loader />}>
                  <CompanyUsers />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.CHAT}
              element={
                <Suspense fallback={<Loader />}>
                  <Chat />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS}
              element={
                <Suspense fallback={<Loader />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.RESET}
              element={
                <Suspense fallback={<Loader />}>
                  <ServiceRestart />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.NEW_PROCESS}
              element={
                <Suspense fallback={<Loader />}>
                  <NewProcess />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SINGLE_SUBPROCESS}
              element={
                <Suspense fallback={<Loader />}>
                  <SingleSubProcess />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SINGLE_SUBSCRIBER_TASK}
              element={
                <Suspense fallback={<Loader />}>
                  <SingleSubscriberTask />
                </Suspense>
              }
            />
            <Route
              path={ERROR.CATCH_ALL}
              element={
                <Suspense fallback={<Loader />}>
                  <Error type={404} />
                </Suspense>
              }
            />
            <Route
              index
              element={<Navigate to={PRIVATE.ADMIN.PAGES.INDEX} />}
            />
          </Route>
        </Route>

        {/* Other Routes */}
        <Route
          path={ERROR.CATCH_ALL}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={404} />
            </Suspense>
          }
        />
        <Route
          path={ERROR.ERROR_403}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={403} />
            </Suspense>
          }
        />
        <Route index element={<Navigate to={PUBLIC.PAGES.LANDING} />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
